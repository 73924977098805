<template>
  <FacilityBar :content="blok" />
  <GlobalBreadcrumb
    v-if="!blok.removeBreadcrumb"
    :page-title="blok.facility_title || blok.commercialName"
  />
  <template v-for="(section, index) in blok.body">
    <div
      v-if="
        !(
          section.component === 'facility-reviews' &&
          config.public['country'] === 'fr'
        )
      "
      :key="section._uid"
      :class="[
        'background',
        'background-' + section.component,
        { alternate: alternateRule(index) },
      ]"
    >
      <StoryblokComponent
        :blok="section"
        :context="facility"
        class="section"
        :collection-news="collectionNews"
        tag="section"
      />
    </div>
  </template>
  <GenericModal
    v-if="
      facilityInfo.menu[0]?.cta_label_1 &&
      facilityInfo.menu[0]?.cta_link_1?.story?.url
    "
    ref="customForm1Modal"
    css-class="custom-form-modal"
  >
    <StoryblokComponent :blok="form1Content.content" :story="facility" />
  </GenericModal>
  <GenericModal
    v-if="
      facilityInfo.menu[0]?.cta_label_2 &&
      facilityInfo.menu[0]?.cta_link_2?.story?.url
    "
    ref="customForm2Modal"
    css-class="custom-form-modal"
  >
    <StoryblokComponent :blok="form2Content.content" :story="facility" />
  </GenericModal>
</template>

<script setup>
const config = useRuntimeConfig();
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  story: {
    type: Object,
    required: true,
  },
  alternateEven: {
    type: Boolean,
    required: false,
  },
});

let facilityReviewsIndex = ref(null);

const alternateRule = (index) => {
  let modifiedIndex = index;
  if (config.public["country"] === "fr" && facilityReviewsIndex.value) {
    modifiedIndex = index < facilityReviewsIndex.value ? index : index - 1;
  }
  return props.alternateEven
    ? modifiedIndex % 2 === 0
    : (modifiedIndex + 1) % 2 === 0;
};

const facilityInfo = ref(props.blok);

// START Structured Data
const getFacilityReviews = props.blok.body.find((item, index) => {
  facilityReviewsIndex.value = index;
  return item.component === "facility-reviews";
});
const structuredDataSeo = JSON.stringify({
  "@context": "https://schema.org/",
  "@type": "LocalBusiness",
  name: props.blok.commercialName || "",
  image: props.blok.og_image?.filename || "",
  brand: useMicroCopy("site.name"),
  address: {
    "@type": "PostalAddress",
    streetAddress: props.blok.address || "",
    addressLocality: props.blok.city || "",
    postalCode: props.blok.postalCode || "",
    addressCountry: props.blok.region || "",
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: props.blok.latitude || "",
    longitude: props.blok.longitude || "",
  },
  description: props.blok.og_description || "",
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: getFacilityReviews?.note || "",
    bestRating: getFacilityReviews?.base_rating || "",
    ratingCount: getFacilityReviews?.total_reviews || "",
  },
});
// END Structured Data

useHead({
  bodyAttrs: {
    class: `header-facility ${
      props.alternateEven ? "header-white alternate-even" : ""
    }`,
  },
  script: [
    {
      type: "application/ld+json",
      innerHTML: structuredDataSeo,
    },
  ],
});

onMounted(() => {
  const header = document.querySelector(".global-header");
  if (
    document.referrer.indexOf(config.public["baseURL"]) > -1 &&
    document.referrer !== window.location.href
  ) {
    if (header) {
      setTimeout(() => {
        window.scrollTo(0, header.offsetHeight);
      }, 500);
    }
  }
});

const facilityUniqueKey = computed(() => {
  return props.blok.facilityUniqueKey;
});

const newsData = computed(() => ({
  facilityUniqueKey: facilityUniqueKey.value,
  isFacilityNews: true,
  facilitySlug: props.story.full_slug,
  showAllCard: true,
}));

const query = gql`
  query {
    facility(facilityUniqueKey: "${facilityUniqueKey.value}") {
    nearbyFacilities(radius: 300, limit: 3) {
      name
      facilityId
      facilityUniqueKey
      facilityType
      city
      country
      zipCode
      address
      addressSupplement
      slug
      isPrivate
      image
    }
    interestedIn(radius: 300, limit: 3) {
      name
      facilityId
      facilityUniqueKey
      facilityType
      city
      country
      zipCode
      address
      addressSupplement
      slug
      isPrivate
      image
    }
  }
}
`;

const facility = ref({});

const { data } = await useAsyncQuery(query);

if (typeof data !== "undefined") {
  facility.value = {
    ...data?.value?.facility,
    ...props.blok,
  };
} else {
  facility.value = {
    ...props.blok,
  };
}

const customForm1Modal = ref(null);
const openCustomForm1Modal = () => {
  customForm1Modal.value?.openModal();
};
const customForm2Modal = ref(null);
const openCustomForm2Modal = () => {
  customForm2Modal.value?.openModal();
};

provide("custom-form-1", openCustomForm1Modal);
provide("custom-form-2", openCustomForm2Modal);

const newsOptions = ref({});
const hubOptions = ref({});
const newsList = ref([]);
const newsHub = ref(null);

const route = useRoute();
const [facilityType, region, facilityFolder] = route.params.slug;

newsOptions.value = {
  version: config.public["storyblokVersion"],
  content_type: "news",
  starts_with: `${config.public["storyblokCountryPrefix"]}/${facilityType}/${region}/${facilityFolder}/`,
  sort_by: "content.date:desc",
};

hubOptions.value = {
  version: config.public["storyblokVersion"],
  content_type: "page-facility-news-hub",
  starts_with: `${config.public["storyblokCountryPrefix"]}/${facilityType}/${region}/${facilityFolder}/`,
};

newsList.value = await useCustomAsyncStoryblok("", newsOptions.value)
  .then((response) => response.value.stories)
  .catch((error) => {
    console.log(error);
  });

newsHub.value = await useCustomAsyncStoryblok("", hubOptions.value)
  .then((response) => response.value?.stories[0])
  .catch((error) => {
    console.log(error);
  });

const collectionNews = computed(() => {
  return getCollectionNews(newsList.value, newsData.value, newsHub.value);
});

const form1Content = facilityInfo.value.menu[0]?.cta_link_1?.story?.url
  ? await useAsyncStoryblok(
      facilityInfo.value.menu[0]?.cta_link_1?.story?.url,
      { version: config.public["storyblokVersion"] }
    )
  : null;
const form2Content = facilityInfo.value.menu[0]?.cta_link_2?.story?.url
  ? await useAsyncStoryblok(
      facilityInfo.value.menu[0]?.cta_link_2?.story?.url,
      { version: config.public["storyblokVersion"] }
    )
  : null;
</script>

<style lang="scss" scoped>
.background:not(.alternate) {
  @include pair-7;
}
</style>

<style lang="scss">
.modal-content .breadcrumb {
  display: none;
}
.custom-form-modal.modal {
  z-index: $z-index-modal + 1;
}
</style>
